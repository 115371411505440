import React from "react";
import BlogBanner from "./BlogBanner/BlogBanner";
import BlogSection from "./BlogSection/BlogSection";
import Header from "../Header/Header";
import { Helmet } from "react-helmet";

const Insights = () => {
    return (
        <>
            <Helmet>
                <meta charset="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Insights</title>
                <meta name="description" content="Discover the Best Stories from Our Bloggers!" />
                <link rel="canonical" href=" https://www.althisolutions.com/blog" />
                <meta name="robots" content="index, follow" />

                <meta property="og:type" content="website" />
                <meta property="og:title" content="Insights" />
                <meta property="og:url" content=" https://www.althisolutions.com/blog" />
                <meta property="og:image" content=" https://althisolutions.com/static/media/old-woman-working-home-office-illustration.1754b09133eb88601d6b.jpg" />
                <meta property="og:description" content="Discover the Best Stories from Our Bloggers!" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Insights" />
                <meta name="twitter:site" content="@https://www.althisolutions.com/blog" />
                <meta name="twitter:description" content="Discover the Best Stories from Our Bloggers!" />
                <meta name="twitter:image" content=" https://althisolutions.com/static/media/old-woman-working-home-office-illustration.1754b09133eb88601d6b.jpg" />
            </Helmet>

            <Header />
            <BlogBanner />
            <BlogSection />
        </>
    );
};

export default Insights;
