import React from 'react';
import './OurServices.scss';
import { FaBuildingLock, FaGraduationCap, FaChromecast, FaReact, FaArrowRight } from 'react-icons/fa6';
import { HiMiniBuildingLibrary } from "react-icons/hi2";
import { LiaLaptopMedicalSolid } from "react-icons/lia";
import { GiHouseKeys } from "react-icons/gi";
import { ImCogs } from "react-icons/im";

const OurServices = () => {
    const services = [
        { icon: <HiMiniBuildingLibrary />, title: 'Banking' },
        { icon: <LiaLaptopMedicalSolid />, title: 'Healthcare' },
        { icon: <FaGraduationCap />, title: 'Education' },
        { icon: <GiHouseKeys />, title: 'Real Estate' },
        { icon: <FaBuildingLock />, title: 'Retail' },
        { icon: <FaReact />, title: 'Life Science' },
        { icon: <ImCogs />, title: 'Manufacturing' },
        { icon: <FaChromecast />, title: 'Communication & Media' },
    ];

    return (
        <div className="our-services">
            <div className="service-heading">
                <button className="service-btn">Our Service</button>
                <h2>Our Impact In Your Industry</h2>
            </div>
            <div className="service-cards">
                {services.map((service, index) => (
                    <div className="service-card" key={index}>
                        <span className="service-icon">{service.icon}</span>
                        <p>{service.title}</p>
                        <span className="service-arrow"><FaArrowRight /></span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default OurServices;
