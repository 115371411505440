const client = require("contentful").createClient({
  space: "kja4jw28hgk4",
  accessToken: "cwBtJjoC7EoI8nocV4hQNKazXIm0lEyAZdEpr7c567g"
});

const getBlogPosts = () => {
  return client.getEntries().then(response => {
    return response?.items ? response.items : [];
  });
};


const getSinglePost = id =>
  client
    .getEntry(id)
    .then((response) =>
      response
    )


export { getBlogPosts, getSinglePost };
