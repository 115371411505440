import React from 'react';
import map from "../../Assets/WhoWeAre/map.png";

const OurPresence = () => {
    return (
        <>
            <div className="ambitious-section">
                <div className="ambitious-section-content">
                    <h2>Our Presence</h2>
                    <p>
                        Our service areas include USA, UK, Middle East, India, and Africa.
                        Once you partner with us, your goals become our goals.
                        Our job has always been to meet your needs and keep your operations running smoothly.
                    </p>
                </div>

                <img src={map} alt="" style={{ width: "-webkit-fill-available", margin: "2rem 0" }} />

                <div className="ambitious-section-content">
                    <p >
                        Hear this, We are expanding our horizons<span style={{ color: "#EE781C" }}>!</span>
                    </p>
                </div>
            </div>

        </>
    )
}

export default OurPresence;