import React from 'react';
import "./WhoWeAreBanner.scss";

const WhoWeAreBanner = () => {
    return (
        <>
            <div className="whoweare-page">
                <div className="whoweare-page-text">
                    <h2>Business is complicated, but we guarantee it won't be boring<span>.</span></h2>
                </div>
            </div>
        </>
    )
}

export default WhoWeAreBanner;