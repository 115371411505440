import React from 'react'
import "../ForbiddenPage/Forbidden.css"
import cartoonimg from "../Media/cartoonimg.png"
import { Link } from 'react-router-dom';
export default function Forbidden() {
  return (
   <div className='forbidden-wrapper'>
    <div className='forbidden-main'>
      <div className='container'>
      <div className='forbidden-contain'>
        
        <div className='rightforbidden-content'>
       <img className='carimg' src={cartoonimg} alt='cartoon'></img>
        </div>
        <div className='leftforbidden-content'>
         <p className='awcontent'>Aww...Don't cry.</p>
         <p className='just-error'>It's just a 404 error</p>
         <div className='forbidden-small'>
         <p>What you’re looking for may have been misplaced in <br />long term memory.</p>
         <div className="forbidden-button">
      <Link to="/" className="for-button">
        Go to home page
      </Link>
    </div>
         
         </div>
        </div>
      </div>
      </div>
     
    </div>
   </div>
   
  )
}
