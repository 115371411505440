import React from 'react';
import './CircleWave.scss';
import userIcon from "../../../Assets/WhoWeAre/Avatar-1.png";
import avatar1 from "../../../Assets/WhoWeAre/Avatar-2.png";
import avatar2 from "../../../Assets/WhoWeAre/Avatar-3.jpg";
import avatar3 from "../../../Assets/WhoWeAre/Avatar-4-Girl.png";
import avatar4 from "../../../Assets/WhoWeAre/Avatar-5.png";
import avatar5 from "../../../Assets/WhoWeAre/Avatar-6-Girl.png";

const CircleWave = () => {
    return (
        <div className="circle-wave-container">
            <div className="circle central-circle">
                <img src={userIcon} alt="User Icon" className="icon" />
            </div>
            <div className="circle circle-2">
                <img src={avatar1} alt="User Avatar" className="avatar avatar-pos-2" />
            </div>
            <div className="circle circle-3">
                <img src={avatar1} alt="User Avatar" className="avatar avatar-pos-3" />
            </div>
            <div className="circle circle-4">
                <img src={avatar1} alt="User Avatar" className="avatar avatar-pos-4" />
                <img src={avatar2} alt="User Avatar" className="avatar avatar-pos-5" />
                <img src={avatar3} alt="User Avatar" className="avatar1 avatar-pos-6" />
                <img src={avatar4} alt="User Avatar" className="avatar2 avatar-pos-7" />
                <img src={avatar5} alt="User Avatar" className="avatar3 avatar-pos-8" />
            </div>
        </div>
    );
};

export default CircleWave;
