import React, { useState } from 'react';
import ISO from "../Assets/ContactUs/ISO.png";
import DPIIT from "../Assets/ContactUs/DPIIT.png";
import "./FooterPage.scss";
import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import whitelogo from '../Media/whitelogo.svg';
import { ImFacebook } from "react-icons/im";
import { FaLinkedinIn } from "react-icons/fa";
import { AiOutlineInstagram } from "react-icons/ai";
import { BsTwitterX } from "react-icons/bs";
import axios from 'axios';
import 'primeicons/primeicons.css';

const FooterPage = () => {

    const [formData, setFormData] = useState({
        email: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };


    const handleSubmit = (e) => {

        if (formData.email.trim() === '') {
            alert('Please enter a valid email address');
        }

        e.preventDefault();

        const apiURL = process.env.REACT_APP_CONTACTUS_HOST;

        axios
            .post(apiURL, formData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then(() => {
                alert("Thank you for subscribing to our newsletter.");
            })
    };


    return (
        <>
            <div className="footer-section-black">
                <div className="footer-section p-6 pb-4">
                    <div className="footer-links">

                        <div className='logosection'>
                            <div className='footer-icons'>
                                <div className='socialmedia-icon'>
                                    <a href='https://www.facebook.com/AlthiSolutions' className='social-media' target='_blank' rel='noopener noreferrer'>
                                        <ImFacebook />
                                    </a>
                                </div>

                                <div className='socialmedia-icon'>
                                    <a href='https://twitter.com/AlthiSolutions/' className='social-media' target='_blank' rel='noopener noreferrer'>
                                        <BsTwitterX />
                                    </a>
                                </div>

                                <div className='socialmedia-icon'>
                                    <a href='https://www.instagram.com/althisolutions/' className='social-media' target='_blank' rel='noopener noreferrer'>
                                        <AiOutlineInstagram />
                                    </a>
                                </div>

                                <div className='socialmedia-icon'>
                                    <a href='https://in.linkedin.com/company/althisolutions' className='social-media' target='_blank' rel='noopener noreferrer'>
                                        <FaLinkedinIn />
                                    </a>
                                </div>
                            </div>

                            <img className='footerlogo' src={whitelogo} alt='White Logo' />

                        </div>

                        <div className="quick-links col m-3">
                            <h4>COMPANY</h4>
                            <ul>
                                <li> <a href="/product">Product</a> </li>
                                <li> <a href="/blog">Blog</a> </li>
                                <li> <a href="/contactus">Support</a> </li>
                            </ul>
                        </div>

                        <div className="quick-links col  m-3">
                            <h4>CONTACT US</h4>
                            <ul>
                                <li>
                                    info@althisolutions.com
                                </li>
                                <li>
                                    +1 959-666-3350 (USA)
                                </li>
                                <li>
                                    04652 236 359 (INDIA)
                                </li>
                            </ul>
                        </div>

                        <div className="quick-links col m-3">
                            <h4>STAY UP TO DATE</h4>
                            <p className="mb-3">Subscribe to our newsletter.</p>
                            <span className="p-input-icon-right">
                                <i className="pi pi-arrow-right" onClick={handleSubmit} />
                                <InputText name="email" type="email" placeholder="Email" value={formData.email} onChange={handleChange} />
                            </span>
                        </div>
                    </div>

                </div>

                <Divider type="solid" />

                <div className="footer-sec px-6">
                    <div className="policy-section">
                        <a href="#">Cookies Policy</a>
                        <a href="#">Terms & Conditions</a>
                        <a href="#">Privacy Policy</a>
                    </div>
                    <div className="logo-sec">
                        <div className="legals col m-3">
                            <img src={ISO} alt="ISO Logo" />
                        </div>

                        <div className="legals col m-3">
                            <img src={DPIIT} alt="DPIIT Logo" />
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default FooterPage;
