import React from 'react';
import { usePosts } from '../../custom-hooks';
import { Card } from 'primereact/card';
import "../BlogSection/BlogSection.scss";

const RelatedContent = ({ currentArticle }) => {
    const [blogs,] = usePosts();

    const relatedArticles = blogs.filter(article => article.sys.id !== currentArticle);

    return (
        <div className="blog-content-section">
            <div className="blog-card-section">
                <div>
                    <h2 className="related-section-heading">Related Articles</h2>
                </div>

                {relatedArticles.length > 0 ? (
                    <ul className='card-content'>
                        {relatedArticles.slice(-3).map((blog, index) =>
                            <div key={index} className="col-4 lg:col-4 col-section">
                                <div className="m-3">
                                    <Card className="new">
                                        <div className="blog-img" >
                                            <img src={blog.fields.blogImage[0].fields.file.url} alt={blog.fields.blogTitle} />
                                        </div>

                                        <div className="blog-text">
                                            <div className="pt-3">
                                                <span >{blog.fields.blogAuthor} | {blog.fields.createdDate}</span> <br />
                                            </div>

                                            <div className="pt-3">
                                                <p className="blogs-heading">{blog.fields.blogTitle}
                                                    <a href={`/blog/${blog.sys.id}`} aria-label="Read more">
                                                        <i className="pi pi-arrow-right" />
                                                    </a>
                                                </p>
                                            </div>
                                        </div>

                                    </Card>
                                </div>
                            </div>
                        )}
                    </ul>
                ) : (
                    <p>No related articles found.</p>
                )}
            </div>
        </div>
    );
};

export default RelatedContent;