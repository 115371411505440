import { useEffect, useState } from "react";

import { getSinglePost } from "../contentful";

export default function useSinglePost(id) {


  const [post, setPost] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // console.log(promise)
  // useEffect(() => {
  //   promise.then(result => {
  //     console.log(result)
  //     setPost(result);
  //     setLoading(false);
  //   });
  // }, [promise]);


  useEffect(() => {
    const getEntryById = async () => {
      try {
        await getSinglePost(id).then((entries) => {
          setPost(entries)
          setLoading(false);
        })
      } catch (error) {
        console.log(`Error fetching authors ${error}`);
        setError(error)
      }
    };
    getEntryById()
  }, [id])

  return [post, isLoading, error];
}
