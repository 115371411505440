import React, { useState, useEffect, useMemo } from "react";
import './WhatWeBelieve.scss';
import Avatar1 from '../../Assets/WhoWeAre/Avatar-1.png';
import Avatar2 from '../../Assets/WhoWeAre/Avatar-2.png';
import Avatar3 from '../../Assets/WhoWeAre/Avatar-3.jpg';
import Avatar4 from '../../Assets/WhoWeAre/Avatar-4-Girl.png';

const WhoWeBelieve = () => {

    const members = useMemo(() => [
        { name: "Swarna Tharanya P K", role: "Human Resource", image: Avatar4 },
        { name: "Shaji P", role: "Technical Lead", image: Avatar4 },
        { name: "Sahaya Abila C", role: "Technical Lead", image: Avatar4 },
        { name: "Edwin Edison J", role: "Marketing Lead", image: Avatar1 },
    ], []);

    const [currentIndex, setCurrentIndex] = useState(0);
    const [prevMember, setPrevMember] = useState(null);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setPrevMember(members[currentIndex]);
            setCurrentIndex((prevIndex) => (prevIndex + 1) % members.length);
        }, 2000);

        return () => clearInterval(intervalId);
    }, [currentIndex, members]);

    const currentMembers = [
        members[currentIndex],
        members[(currentIndex + 1) % members.length],
        members[(currentIndex + 2) % members.length],
    ];

    return (
        <div className="whatwebelieve-section">
            <div className="whatwebelieve-section-content">
                <h2>What We Believe</h2>
                <p>
                    The company is more important than the journey or destination.
                    So we make sure our clients know that the entire Althi Solutions team is behind them.
                    24/7 never stopped us from responding to challenges for our clients.
                </p>
                <p>Stop by anytime to see our team in action!</p>
            </div>

            <div className="team-section">
                <div className="team-members-list">
                    {prevMember && (
                        <div className="team-member delayed" key={prevMember.name}>
                            <div>
                                <img src={prevMember.image} alt={prevMember.name} />
                                <div>
                                    <h3>{prevMember.name}</h3>
                                    <p>{prevMember.role}</p>
                                </div>
                            </div>
                        </div>
                    )}

                    {currentMembers.map((member, index) => (
                        <div className={`team-member ${index === 0 && "top"} ${index === 0 && "new"}`} key={index}>
                            <div>
                                <img src={member.image} alt={member.name} />
                                <div>
                                    <h3>{member.name}</h3>
                                    <p>{member.role}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default WhoWeBelieve;