import React from 'react';
import "./Ambitious.scss"
import CircleWave from '../Ambitious/CircleWave/CircleWave';

const Ambitious = () => {
    return (
        <>
            <div className="ambitious-section">
                <div className="ambitious-section-content">
                    <h2>Building Ambitious Future</h2>
                    <p>Through Innovation and collective knowledge Althi Solutions is
                        an IT services, consulting, and business solutions organization.
                        We serve both big and small businesses in various industries such
                        as manufacturing, healthcare, finance, education, etc.
                    </p>
                </div>
            </div>

            <CircleWave />
        </>
    )
}

export default Ambitious;