import React, { useState } from 'react';
import "./Header.scss";
import logo from '../Media/whitelogo.svg';
import { useLocation } from 'react-router-dom';

const Header = () => {
    const [showProducts, setShowProducts] = useState(false);
    const [showWhatWeDo, setShowWhatWeDo] = useState(false);
    const location = useLocation();

    const toggleProducts = () => {
        setShowProducts(!showProducts);
    };

    const toggleWhatWeDo = () => {
        setShowWhatWeDo(!showWhatWeDo);
    };

    const isActive = (path) => location.pathname === path;

    const isWhatWeDoActive = location.pathname.startsWith("/whatWeDo");

    return (
        <nav>
            <div className="wrapper">
                <div className="logo">
                    <a href="/" target={"_self"}>
                        <img className="logo" src={logo} alt="Logo" />
                    </a>
                </div>
                <input type="radio" name="slider" id="menu-btn" />
                <input type="radio" name="slider" id="close-btn" />
                <ul className="nav-links">
                    <label htmlFor="close-btn" className="btn close-btn">
                        <i className="pi pi-times"></i>
                    </label>

                    <li className={`what-we-do ${showWhatWeDo ? 'show-what-we-do' : ''}`}>
                        <a href="#" className={`desktop-item ${isWhatWeDoActive ? 'activeMenu' : ''}`}>What We Do</a>
                        <input type="checkbox" id="showMega" />
                        <label htmlFor="showMega" className="mobile-item sub-section" onClick={toggleWhatWeDo}>
                            <div className="what-we-do-text">What We Do</div>
                            <div className="what-we-do-symbol">{showWhatWeDo ? '-' : '+'}</div>
                        </label>

                        <div className="mega-box">
                            <div className="contents">
                                <div className="row overview">
                                    <header>Overview</header>
                                    <ul className="mega-link-content">
                                        <h2>Building On Trust<span>.</span></h2>
                                        <p>
                                            Our core business operation is to optimize your business through technology.
                                            We explore a wide range of capabilities that meets client's needs.
                                        </p>
                                    </ul>
                                </div>
                                <div className="row products">
                                    <header className='desktop-item'>Products</header>
                                    <div className="desktop-item">
                                        <ul className="submenus">
                                            <li className={isActive("/whatWeDo/VCheck") ? "active" : ""}><a href="/whatWeDo/VCheck">VCheck</a></li>
                                            <li className={isActive("/whatWeDo/Exaan") ? "active" : ""}><a href="/whatWeDo/Exaan">Exaan</a></li>
                                            <li className={isActive("/whatWeDo/Acaia") ? "active" : ""}><a href="/whatWeDo/Acaia">Acaia</a></li>
                                        </ul>
                                    </div>

                                    <header className='mobile-item sub-section' onClick={toggleProducts}>
                                        <div className='product-text'>Products</div>
                                        <div className='product-symbol'>{showProducts ? '-' : '+'}</div>
                                    </header>

                                    {
                                        showProducts && (
                                            <div className="mobile-item">
                                                <ul className={`mega-link ${showProducts ? 'show' : ''}`}>
                                                    <li className={isActive("/whatWeDo/VCheck") ? "active" : ""}><a href="/whatWeDo/VCheck">VCheck</a></li>
                                                    <li className={isActive("/whatWeDo/Exaan") ? "active" : ""}><a href="/whatWeDo/Exaan">Exaan</a></li>
                                                    <li className={isActive("/whatWeDo/Acaia") ? "active" : ""}><a href="/whatWeDo/Acaia">Acaia</a></li>
                                                </ul>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className={isActive("/whoWeAre") ? "active" : ""}><a href="/whoWeAre"> Who We Are</a></li>
                    <li className={isActive("/blog") ? "active" : ""}><a href="/blog"> Insights</a></li>
                    <li className={isActive("/careers") ? "active" : ""}><a href="/careers"> Careers</a></li>
                    <li className={isActive("/contactus") ? "active" : ""}><a href="/contactus"> Contact Us</a></li>
                </ul>

                <label htmlFor="menu-btn" className="btn menu-btn"><i className="pi pi-bars"></i></label>
            </div>
        </nav>
    );
};

export default Header;