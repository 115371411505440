import React, { useState } from 'react';
import { useSinglePost } from '../../custom-hooks';
import { useParams } from 'react-router-dom';
import { IoLogoLinkedin } from 'react-icons/io';
import { FaFacebook, FaInstagram, FaSquareXTwitter } from 'react-icons/fa6';
import ReactMarkdown from 'react-markdown';
import './BlogView.scss';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import axios from 'axios';
import Header from '../../Header/Header';
import RelatedContent from './RelatedContent';

const BlogView = () => {
    const { id } = useParams();
    const [post,] = useSinglePost(id);

    const handleShare = (option) => {

        switch (option) {
            case 'Instagram':
                window.open(`https://www.instagram.com/`, '_blank');
                break;
            case 'Facebook':
                window.open(`https://www.facebook.com/`, '_blank');
                break;
            case 'X':
                window.open(`https://x.com/`, '_blank');
                break;
            case 'LinkedIn':
                window.open(`https://www.linkedin.com/`, '_blank');
                break;
            default:
                break;
        }
    };

    const [formData, setFormData] = useState({
        email: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubscribe = (e) => {
        if (formData.email.trim() === '') {
            alert('Please enter a valid email address');
        }

        e.preventDefault();

        const apiURL = process.env.REACT_APP_CONTACTUS_HOST;

        axios
            .post(apiURL, formData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then(() => {
                alert("Thank you for Subscribing !");
            })
    }

    return (
        <>
            <Header />

            <div className="blog-view">
                <img
                    className="blog-view-img"
                    src={post?.fields?.blogImage[0]?.fields?.file.url}
                    alt={post?.fields?.blogTitle}
                />

                <div className="blog-view-heading">
                    <h2 className="blog-view-intro-title">{post?.fields?.blogTitle}</h2>
                    <p>{post?.fields?.blogAuthor} | {post?.fields?.createdDate}</p>
                </div>

                <div className="blog-view-intro">
                    <div className="blog-view-card">
                        <p className="blog-view-intro-description">{post?.fields?.blogSummary}</p>

                        <div className="blog-view-body">
                            <ReactMarkdown children={post?.fields?.blogContent} />
                        </div>
                    </div>

                    <div className="blog-share">
                        <div className="blog-share-icon">
                            <div onClick={() => handleShare('Facebook')}>
                                <FaFacebook className='social-icon' />
                            </div>
                            <div onClick={() => handleShare('X')}>
                                <FaSquareXTwitter className='social-icon' />
                            </div>
                            <div onClick={() => handleShare('Instagram')}>
                                <FaInstagram className='social-icon' />
                            </div>
                            <div onClick={() => handleShare('LinkedIn')}>
                                <IoLogoLinkedin className='social-icon' />
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <div className="blog-subscribe">
                <div className="blog-subscribe-section">
                    <div className="blog-subscribe-text">
                        <h2>Subscribe To Our Blog</h2>
                        <InputText className="text-box" placeholder="Enter Email" value={formData.email} onChange={handleChange}></InputText>
                        <Button className="subscribe-btn" onClick={() => handleSubscribe()}>Subscribe</Button>
                    </div>
                </div>
            </div>

            <RelatedContent currentArticle={id} />

        </>
    )
}

export default BlogView;
