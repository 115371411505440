import React from 'react';
import Header from '../Header/Header';
import BannerSection from './BannerSection/BannerSection';
import OurServices from './OurServices/OurServices';
import WhatWeDo from './WhatWeDo/WhatWeDo';
import OurClients from './OurClients/OurClients';
import Founder from './Founder/Founder';
import { Helmet } from 'react-helmet';

const Home = () => {
    return (
        <>

            <Helmet>
                <meta charset="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Althi Solutions | Information Technology Company</title>
                <meta name="description" content="Global IT services, consulting, and software development service provider to enterprises in finance, healthcare, and e-commerce since 2021." />
                <link rel="canonical" href="https://www.althisolutions.com/" />
                <meta name="robots" content="index, follow" />

                <meta property="og:type" content="website" />
                <meta property="og:title" content="Althi Solutions | Information Technology Company" />
                <meta property="og:url" content="https://www.althisolutions.com/" />
                <meta property="og:image" content="https://www.althisolutions.com/icon.svg" />
                <meta property="og:description" content="Global IT services, consulting, and software development service provider to enterprises in finance, healthcare, and e-commerce since 2021." />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Althi Solutions | Information Technology Company" />
                <meta name="twitter:site" content="@https://www.althisolutions.com/" />
                <meta name="twitter:description" content="Global IT services, consulting, and software development service provider to enterprises in finance, healthcare, and e-commerce since 2021." />
                <meta name="twitter:image" content="https://www.althisolutions.com/static/media/BannerImage.45e21167ba9b13363753.jpg" />
            </Helmet>

            <Header />
            <BannerSection />
            <OurServices />
            <WhatWeDo />
            <Founder />
            <OurClients />
        </>
    )
}

export default Home;