import React from 'react';
import './BannerSection.scss';

const BannerSection = () => {
    return (
        <div className="banner-section">
            <div className="banner-content">
                <h1>Bridging the gap between vision and reality</h1>
            </div>
        </div>
    );
};

export default BannerSection;
